import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UsersView from '../views/UsersView.vue'
import AddUserView from '../views/AddUserView.vue'
import AddUserSingleView from '../views/AddUser/AddUserSingleView.vue'
import AddUserBatchView from '../views/AddUser/AddUserBatchView.vue'
import AddUserImportView from '../views/AddUser/AddUserImportView.vue'
import UserDetailsView from '../views/UserDetailsView.vue'
import ManagementView from '../views/ManagementView.vue'
import AddManagementView from '../views/AddManagementView.vue'
import ManagementDetailsView from '../views/ManagementDetailsView.vue'
import GroupsView from '../views/GroupsView.vue'
import LoginView from '../views/LoginView.vue'
import AuditView from '../views/AuditView.vue'
import AddGroupView from '../views/AddGroupView.vue'
import ForgotResetPasswordView from '../views/ForgotResetPasswordView.vue'
import ResendPasswordView from '../views/ResendPasswordView.vue'
import GroupDetailsView from '../views/GroupDetailsView.vue'
import VouchersView from '../views/VouchersView.vue'
import AddVoucherView from '../views/AddVoucherView.vue'
import BlendedCareView from '../views/BlendedCareView.vue'
import DeletionView from '../views/DeletionView.vue'
import InsurersView from '../views/InsurersView.vue'
import InsurerDetailsView from '../views/InsurerDetailsView.vue'
import AddInsurerView from '../views/AddInsurerView.vue'
import ToolsView from '../views/Tools/ToolsView.vue'
import ToolsProcessView from '../views/Tools/ToolsProcessView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/users/:group',
    name: 'UsersView',
    component: UsersView,
    props: true
  },
  {
    path: '/add-user/:group',
    name: 'AddUserView',
    component: AddUserView,
    props: true,
    children: [
      {
        path: 'single',
        name: 'AddUserSingleView',
        component: AddUserSingleView,
        props: true,
      },
      {
        path: 'batch',
        component: AddUserBatchView,
        props: true,
      },
      {
        path: 'import',
        component: AddUserImportView,
      }
    ]
  },
  {
    path: '/user-details/:id',
    name: 'UserDetailsView',
    component: UserDetailsView,
    props: true
  },
  {
    path: '/user-details/:id/audit',
    name: 'AuditView',
    component: AuditView,
    props: true
  },
  {
    path: '/management',
    name: 'ManagementView',
    component: ManagementView
  },
  {
    path: '/add-management',
    name: 'AddManagementView',
    component: AddManagementView
  },
  {
    path: '/management-details/:id',
    name: 'ManagementDetailsView',
    component: ManagementDetailsView,
    props: true
  },
  {
    path: '/groups',
    name: 'GroupsView',
    component: GroupsView
  },
  {
    path: '/add-group',
    name: 'AddGroupView',
    component: AddGroupView
  },
  {
    path: '/group-details/:id',
    name: 'GroupDetailsView',
    component: GroupDetailsView,
    props: true
  },
  {
    path: '/vouchers',
    name: 'VouchersView',
    component: VouchersView,
  },
  {
    path: '/vouchers/add',
    name: 'AddVoucherView',
    component: AddVoucherView
  },
  {
    path: '/blended-care',
    redirect: '/users/all'
  },
  {
    path: '/blended-care/:region',
    name: 'BlendedCareView',
    component: BlendedCareView
  },
  {
    path: '/deletion',
    name: 'DeletionView',
    component: DeletionView
  },
  {
    path: '/insurers',
    name: 'InsurersView',
    component: InsurersView
  },
  {
    path: '/insurers/add',
    name: 'AddInsurerView',
    component: AddInsurerView
  },
  {
    path: '/insurer-details/:id',
    name: 'InsurerDetailsView',
    component: InsurerDetailsView,
    props: true
  },
  {
    path: '/tools',
    name: 'ToolsView',
    component: ToolsView
  },
  {
    path: '/tools/process',
    name: 'ToolsProcessView',
    component: ToolsProcessView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/identity/forgot-reset-password',
    name: 'ForgotResetPasswordView',
    component: ForgotResetPasswordView
  },
  {
    path: '/resend-password',
    name: 'ResendPasswordView',
    component: ResendPasswordView
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    redirect: to => {
      return {
        path: '/users/all',
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/identity/forgot-reset-password', '/resend-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
