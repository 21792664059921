
import api from '@/services/api'
import { defineComponent, ref, nextTick } from 'vue'
import { Group, GroupRequest, GroupTelecoachRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { FileUploadSelectEvent } from 'primevue/fileupload'
import imageValidator from '@/classes/ImageValidatorClass'
import { AxiosResponse } from 'axios'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const validator = new imageValidator()
    const newGroup = ref<GroupRequest>({
      color: 'ff0000',
      blendedCareAvailable: false,
      infoScreenAvailable: false
    } as GroupRequest)
    const groupTelecoach = ref<GroupTelecoachRequest>({
      telecoachGreenBanner: false,
      telecoachPromoScreens: false,
      telecoachSettings: false,
      telecoachCalls: 5,
      telecoachFreeConsultAds: false
    } as GroupTelecoachRequest)
    const formValid = ref<boolean>(false)
    const fieldValidation = ref({
      name: '',
      prefix: ''
    })
    const imageFile = ref()
    const previewImage = ref('')
    const uploadReady = ref(true)

    const handleSubmit = async () => {
      await validateForm()
      if (!formValid.value) {
        return false
      }

      api.post('manager/group', JSON.stringify(newGroup.value))
        .then((res: AxiosResponse) => {
          const groupResponse: Group = res.data.data
          groupTelecoach.value.groupExternalId = groupResponse.externalId;

          console.log(groupTelecoach.value)
          api
            .put(`manager/group/telecoachSettings`, JSON.stringify(groupTelecoach.value))
            .catch(() => {
              toast.add({ severity: 'error', summary: 'Something went wrong with setting blended care flags', life: 3000 })
            })

          if (imageFile.value) {
            const formData = new FormData()
            formData.append("file", imageFile.value)
            api.put(`manager/group/upload/${groupResponse.externalId}`, formData)
              .then(() => {
                store.dispatch('fetchGroups')
                router.push('/groups')
              })
              .catch(() => {
                toast.add({ severity: 'error', summary: 'There was an error while uploading a file.', life: 3000 })
              })
          } else {
            store.dispatch('fetchGroups')
            router.push('/groups')
          }
        })
        .catch(() => {
          toast.add({ severity: 'error', summary: 'Something went wrong. Check if that group/prefix already exists.', life: 3000 })
        })
    }

    const validateForm = () => {
      fieldValidation.value.name = newGroup.value.name.length <= 0 ? 'Group must have name' : ''
      fieldValidation.value.prefix = newGroup.value.initials?.length != 3 ? 'Groups initials must be exactly 3 characters' : ''

      formValid.value = !fieldValidation.value.name.length && !fieldValidation.value.prefix.length
    }

    const resetUpload = async () => {
      uploadReady.value = false
      await nextTick()
      uploadReady.value = true
    }

    const onFileSelect = (event: FileUploadSelectEvent) => {
      const file = event.files[0]
      if (!file) return

      imageFile.value = file
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.onload = async () => {
          if (validator.validateImage(image, file)) {
            previewImage.value = reader.result as string
          } else {
            imageFile.value = null
            await resetUpload()
            toast.add({ severity: 'error', summary: 'Invalid image dimensions or size.', life: 3000 })
          }
        }
        image.src = reader.result as string
      }
      reader.readAsDataURL(file)
    }

    const clearPreview = () => {
      previewImage.value = ''
    }

    return {
      newGroup,
      groupTelecoach,
      formValid,
      fieldValidation,
      previewImage,
      uploadReady,
      handleSubmit,
      onFileSelect,
      clearPreview
    }
  }
})
