
import api from '@/services/api'
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { DropdownItem, entries, FiltrationItem, PaginationBody, PaginationHeader, PostFilterMap } from '@/types/Misc'
import { BlendedCareUser, User, TelecoachUser } from '@/types/User'
import { format } from 'date-fns'
import { PaginBodyDefault, PaginHeaderDefault } from '@/consts/PaginationConst'
import { useStore } from 'vuex'
import { AccessRole } from '@/enums/UserRole'
import { Group } from '@/types/Groups'
import { BlendedCareCall, BlendedCareCallRequest, BlendedCareAssignTelecoachRequest, BlendedCareNote, DashboardInfo } from '@/types/BlendedCare'
import { parseDateLongFormat, parseDateLongFormatFromString } from '@/helpers/table-utils'
import { useToast } from 'primevue/usetoast'
import { toastError } from '@/helpers/error-handling'
import { ContactStage } from "@/enums/ContactStage";
import { getTokenPayload } from "@/helpers/token";

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const route = useRoute()
    const region = ref<string>(route.params.region as string || 'dach')

    console.log("Region Selected", region.value);
    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })
    const callsTableHeader = ref<PaginationHeader>({ ...PaginHeaderDefault })

    store.dispatch('fetchBlendedCare',
      {
        paginationData: tableBody.value,
        filters: {
          region: {
            type: 'enumFilter',
            name: 'region',
            label: 'Region',
            value: region.value || '',
          }
        }
      })

    const groups = computed<Group[]>(() => store.state.groupList)

    const users = computed<BlendedCareUser[]>(() => store.state.blendedCareList)
    const userCount = computed<number>(() => store.state.blendedCareCount)
    const userRole = computed(() => store.state.userType)

    const contactStageEnum = ContactStage
    const contactStageOptions = Object.keys(contactStageEnum).map(key => ({
      label: contactStageEnum[key as keyof typeof contactStageEnum], value: key
    }) as DropdownItem)

    const completionStatusOptions = Array.from({ length: 6 }, (_, i) => i).map(i => ({
      label: i.toString(), value: i
    }) as DropdownItem)

    const telecoaches = ref<TelecoachUser[]>([])

    const filterSelectedTelecoach = ref<User | null>(null)
    const filterSelectedContactStage = ref<DropdownItem | null>(null)
    const filterSelectedCompletionStatus = ref<DropdownItem | null>(null)

    const tcAssignmentSelectedTelecoach = ref<TelecoachUser | null>(null)
    const tcAssignmentSelectedUser = ref<BlendedCareUser | null>(null)
    const currentTelecoach = computed(() => {
      return telecoaches.value.find((tc: TelecoachUser) => tc.externalId === tcAssignmentSelectedUser.value?.telecoachExternalId)
    })

    const contactStageSelected = ref<DropdownItem | null>(null)
    const contactStageSelectedUser = ref<BlendedCareUser | null>(null)
    const currentContactStage = computed(() => {
      return contactStageOptions.find((cs: DropdownItem) => cs.value === contactStageSelectedUser.value?.contactStage)
    })

    const newNoteContent = ref<string>('')

    const dashboardInfo = ref<DashboardInfo | null>(null)

    const getTelecoachesHeaders = () => {
      return {
        'X-PageSize': 100,
        'X-PageNumber': 1,
        'X-Query': '',
        'X-Order': '',
        'X-Desc': true,
      }
    }

    const getFilters = (): PostFilterMap => {
      return {
        region: {
          type: 'enumFilter',
          name: 'region',
          label: 'Region',
          value: region.value || '',
        },
        telecoach: {
          type: 'valueFilter',
          name: 'telecoachExternalId',
          label: 'Telecoach ExternalId',
          value: filterSelectedTelecoach.value?.externalId
        },
        contactStage: {
          type: 'enumFilter',
          name: 'contactStage',
          label: 'Contact Stage',
          value: filterSelectedContactStage.value?.value,
        },
        completionStatus: {
          type: 'quantityFilter',
          name: 'completionStatus',
          label: 'Completion Status',
          value: filterSelectedCompletionStatus.value?.value,
        }
      }
    }

    const resetPage = () => {
      tableBody.value.pageNumber = 1
    }

    const refetchBlendedCare = () => {
      store.dispatch('fetchBlendedCare', { paginationData: tableBody.value, filters: getFilters() })
    }

    const refetchDashboardData = () => {
      const { access_token } = store.state.user
      const { externalId } = getTokenPayload(access_token)

      if (externalId) {
        api.get(`telecoach/blendedcare/dashboard/${externalId}`)
          .then(res => {
            dashboardInfo.value = res.data.data
          })
          .catch((err: any) => toastError(toast, err))
      }
    }

    const refetchTelecoachList = () => {
      api.get('manager/user/filter/telecoach', {
        headers: getTelecoachesHeaders()
      })
        .then(res => {
          telecoaches.value = res?.data.data.list.filter((user: TelecoachUser) => user.activated)
        })
        .catch((err: any) => toastError(toast, err))
    }

    watch(() => route.params.region, (newRegion) => {
      region.value = newRegion as string || 'dach';
      refetchBlendedCare();
    });

    watch([filterSelectedTelecoach, filterSelectedContactStage, filterSelectedCompletionStatus], () => {
      resetPage()
      refetchBlendedCare()
    })

    onMounted(() => {
      refetchTelecoachList()
      refetchDashboardData()
    })

    let searchTimeout = ref<number | null>(0)

    const callModalOpen = ref(false)
    const settingsModalOpen = ref(false)
    const blendedCareCalls = ref<BlendedCareCall[]>([] as BlendedCareCall[])
    const blendedCareNotes = ref<BlendedCareNote[]>([] as BlendedCareNote[])
    const calendarValue = ref<string | null>(null)
    const nextMeetingTimeCalendarValue = ref<string | null>(null)

    watch([settingsModalOpen], () => {
      if (!settingsModalOpen.value) {
        tcAssignmentSelectedTelecoach.value = null
        tcAssignmentSelectedUser.value = null
      }
    })

    watch([callModalOpen], () => {
      if (!callModalOpen.value) {
        contactStageSelected.value = null
        contactStageSelectedUser.value = null
        newNoteContent.value = ''
        nextMeetingTimeCalendarValue.value = null
      }
    })

    watch([users], () => {
      const changedCallsOrContactStage = callModalOpen.value && contactStageSelectedUser.value
      const changedTcAssignment = settingsModalOpen.value && tcAssignmentSelectedUser.value

      if (changedCallsOrContactStage) {
        const selectedUser = users.value.find(user => user.externalId === contactStageSelectedUser.value?.externalId)

        if (selectedUser) {
          contactStageSelectedUser.value = selectedUser
        }
      }

      if (changedTcAssignment) {
        const selectedUser = users.value.find(user => user.externalId === tcAssignmentSelectedUser.value?.externalId)

        if (selectedUser) {
          tcAssignmentSelectedUser.value = selectedUser
        }
      }
    })

    type options = "MedicalCareUpdated" | "Created" | "NextMeetingTime" | "ContactStage" | "CompletionStatus"
    const sorts = ref<FiltrationItem<options>>({
      NextMeetingTime: {
        config: {
          descending: false,
          enabled: false
        }
      },
      Created: {
        config: {
          descending: false,
          enabled: false
        }
      },
      MedicalCareUpdated: {
        config: {
          descending: false,
          enabled: false
        }
      },
      ContactStage: {
        config: {
          descending: false,
          enabled: false
        }
      },
      CompletionStatus: {
        config: {
          descending: false,
          enabled: false
        }
      }
    })

    const changeSorts = (sortName: options): void => {
      const sort = sorts.value[sortName]

      entries(sorts.value)
        .filter(([key]) => key != sortName)
        .forEach(([, value]) => value.config.enabled = false)

      if (!sort.config.enabled) {
        sort.config.enabled = true
      } else {
        sort.config.descending = !sort.config.descending
      }

      tableBody.value.descending = sort.config.descending
      tableBody.value.orderBy = sortName
      resetPage()
      refetchBlendedCare()
    }

    const returnDate = (ts: number): string => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy HH:mm:ss') : '-'
    }

    const searchUsers = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          resetPage()
          refetchBlendedCare()
        }
      }, 800)
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload }
      refetchBlendedCare()
    }

    const findGroup = (groupId: string): Group | undefined => {
      return groups.value.find(item => item.externalId === groupId)
    }

    const clearCall = (call: BlendedCareCall) => {
      api.delete(`manager/blendedcare/calls/${call.externalId}`, {
        headers: {
        }
      })
        .then(() => {
          toast.add({ severity: 'info', summary: 'Call deleted', detail: `Call '${call.name}' has been deleted`, life: 3000 })

          refetchBlendedCare()
          refetchDashboardData()
        })
        .catch((err: any) => toastError(toast, err))
      call.timeOfCall = undefined;
    }

    const refetchNotes = (externalId: string) => {
      api.get(`manager/blendedcare/notes/list/${externalId}`)
        .then(res => {
          blendedCareNotes.value = res.data.data.sort((a: BlendedCareNote, b: BlendedCareNote) => b.created - a.created)
        })
        .catch((err: Error) => toastError(toast, err))
    }

    const openCallsModal = (user: BlendedCareUser) => {
      api.get(`manager/blendedcare/calls/${user.externalId}`, {
        headers: {
          ...callsTableHeader.value
        }
      })
        .then(res => {
          blendedCareCalls.value = res.data.data
          callModalOpen.value = true
        })
        .catch((err: Error) => toastError(toast, err))

      refetchNotes(user.externalId)

      contactStageSelectedUser.value = user;
    }

    const openSettingsModal = (user: BlendedCareUser) => {
      settingsModalOpen.value = true
      tcAssignmentSelectedUser.value = user;
    }

    const setContactStage = () => {
      if (!contactStageSelected.value) {
        return
      }

      const contactStageUpdate = {
        externalId: contactStageSelectedUser.value?.externalId,
        contactStage: contactStageSelected.value?.value
      }

      api.put('manager/blendedcare/profile/contactstage', JSON.stringify(contactStageUpdate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Contact stage updated', detail: `Contact stage has been updated`, life: 3000 })

          refetchBlendedCare()
        })
        .catch((err: Error) => toastError(toast, err))
    }

    const addNote = () => {
      if (!newNoteContent.value) {
        return
      }

      const noteCreate = {
        externalId: contactStageSelectedUser.value?.externalId,
        content: newNoteContent.value
      }

      api.post('manager/blendedcare/notes', JSON.stringify(noteCreate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Note added', detail: `Note has been added`, life: 3000 })

          newNoteContent.value = ''
          refetchNotes(contactStageSelectedUser.value?.externalId || '')
        })
        .catch((err: Error) => toastError(toast, err))

    }

    const assignTelecoach = () => {
      if (!tcAssignmentSelectedTelecoach.value || !tcAssignmentSelectedUser.value) {
        return
      }

      const telecoachName = tcAssignmentSelectedTelecoach.value.name;
      const userId = tcAssignmentSelectedUser.value.externalId;

      const tcAssignmentUpdate: BlendedCareAssignTelecoachRequest = {
        userExternalId: tcAssignmentSelectedUser.value.externalId,
        telecoachExternalId: tcAssignmentSelectedTelecoach.value.externalId
      }

      api.post('manager/blendedcare/assign/telecoach', JSON.stringify(tcAssignmentUpdate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Telecoach assigned', detail: `Telecoach '${telecoachName}' has been assigned to user with ID ${userId}`, life: 8000 })

          refetchBlendedCare()
        })
        .catch((err: Error) => toastError(toast, err))
    }

    const setCallTime = (call: BlendedCareCall) => {
      call.timeOfCall = Date.parse(calendarValue.value || "") / 1000

      const callUpdate: BlendedCareCallRequest = {
        externalId: call.externalId,
        isSuccess: true,
        timeOfCall: call.timeOfCall
      }

      api.post('manager/blendedcare/calls', JSON.stringify(callUpdate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Call updated', detail: `Call '${call.name}' has been updated`, life: 3000 })

          refetchBlendedCare()
          refetchDashboardData()
        })
        .catch((err: Error) => toastError(toast, err))
    }

    const clearNextMeetingTime = () => {
      if (nextMeetingTimeCalendarValue.value && !contactStageSelectedUser.value?.nextMeetingTime) {
        nextMeetingTimeCalendarValue.value = null
        return
      }

      if (nextMeetingTimeCalendarValue.value) {
        const nextMeetingTimeUpdate = {
          externalId: contactStageSelectedUser.value?.externalId,
          nextMeetingTime: null
        }

        api.post('manager/blendedcare/profile/nextmeetingtime', JSON.stringify(nextMeetingTimeUpdate))
          .then(() => {
            toast.add({ severity: 'info', summary: 'Next appointment time cleared', detail: `Next appointment time has been cleared`, life: 3000 })

            nextMeetingTimeCalendarValue.value = null
            refetchBlendedCare()
          })
          .catch((err: Error) => toastError(toast, err))
      }
    }

    const saveNextMeetingTime = () => {
      if (!nextMeetingTimeCalendarValue.value) {
        return
      }

      const nextMeetingTimeUpdate = {
        externalId: contactStageSelectedUser.value?.externalId,
        nextMeetingTime: Date.parse(nextMeetingTimeCalendarValue.value || "") / 1000
      }

      api.post('manager/blendedcare/profile/nextmeetingtime', JSON.stringify(nextMeetingTimeUpdate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Next appointment time updated', detail: `Next appointment time has been updated`, life: 3000 })

          refetchBlendedCare()
        })
        .catch((err: Error) => toastError(toast, err))
    }

    return {
      users,
      userCount,
      tableBody,
      userRole,
      AccessRole,
      callModalOpen,
      settingsModalOpen,
      blendedCareCalls,
      calendarValue,
      sorts,
      contactStageOptions,
      contactStageSelected,
      telecoaches,
      filterSelectedTelecoach,
      filterSelectedContactStage,
      filterSelectedCompletionStatus,
      tcAssignmentSelectedTelecoach,
      tcAssignmentSelectedUser,
      contactStageSelectedUser,
      currentTelecoach,
      currentContactStage,
      newNoteContent,
      blendedCareNotes,
      nextMeetingTimeCalendarValue,
      dashboardInfo,
      saveNextMeetingTime,
      clearNextMeetingTime,
      addNote,
      completionStatusOptions,
      assignTelecoach,
      setContactStage,
      clearCall,
      parseDateLongFormat,
      parseDateLongFormatFromString,
      searchUsers,
      returnDate,
      paginChange,
      findGroup,
      openCallsModal,
      openSettingsModal,
      setCallTime,
      changeSorts,
    }
  },
})
